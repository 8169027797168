<template>
  <div id="app">
    <tabs :tabList="tabList" @changeIndex="changeIndex"></tabs>
    <router-view />
  </div>
</template>

<script>
import tabs from "../src/components/tabs.vue";
export default {
  data() {
    return {
      tabList: [
        { active: true, value: "首页", link: "/" },
        { active: false, value: "定制开发", link: "/development" },
        { active: false, value: "客户案例", link: "/case" },
        { active: false, value: "关于我们", link: "/about" },
      ],
    };
  },
  components: {
    tabs,
  },
  methods:{
    changeIndex(e){
      this.$router.push(this.tabList[e].link)
    }
  }
};
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import CustomDevelopment from '../views/customDevelopment.vue'
import Case from '../views/case.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '上海素联信息科技有限公司'
    }
  },
  {
    path: '/development',
    name: 'CustomDevelopment',
    component: CustomDevelopment,
    meta: {
      title: '上海素联信息科技有限公司'
    }
  },
  {
    path: '/case',
    name: 'Case',
    component: Case,
    meta: {
      title: '上海素联信息科技有限公司'
    }
  },
  {
    path: '/about',
    name: 'aboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue'),
    meta: {
      title: '上海素联信息科技有限公司'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div class="tabContainer">
    <div class="logo">
      <img src="../assets/static/logoTwo.png" alt="" />
    </div>
    <div class="tags">
      <div
        :class="['tagsItem', { tagsItemActive: index == tabindex }]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeIndex(index)"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="search">
      <img src="../assets/static/tab/search.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabindex: 0,
    };
  },
  methods: {
    changeIndex(index) {
      this.tabindex = index;
      this.$emit("changeIndex", index);
    },
  },
  props: {
    tabList: {
      type: Array,
    },
    // tabindex: {
    //   type: Number,
    //   default: 0,
    // },
  },
};
</script>
<style lang="scss" scoped>
.tabContainer {
  display: flex;
  align-items: center;
  height: 200px;
}
.logo {
  margin-left: 150px;
  img {
    width: 300px;
    height: 240px;
  }
}
.search {
  margin-left: 65px;
}
.tags {
  margin-left: 445px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  .tagsItem {
    font-size: 20px;
    font-weight: 500;
    color: #312c2c;
    cursor: pointer;
  }
  .tagsItemActive {
    color: #4e8dff;
    font-size: 21px;
    font-weight: 700;
  }
  .tagsItemActive::before {
    content: "";
    width: 200px;
    height: 2px;
    background-color: #ffcb15;
  }
}
</style>
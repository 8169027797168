<template>
	<div>
		<div class="homeTop">
			<img src="../assets/static/customDevelopment/index_01.png" />
		</div>
		<div class="contactUs">
			<div class="top">
				<!-- <div class="item">
          <img src="../assets/static/company.png" />
          <input type="text" placeholder="素联科技" />
        </div> -->
				<div class="item">
					<img src="../assets/static/people.png" />
					<input type="text" placeholder="请输入您的姓名" />
				</div>
				<div class="item">
					<img src="../assets/static/phone.png" />
					<input type="text" placeholder="请输入您的手机号码" />
				</div>
				<div class="item">
					<img src="../assets/static/find.png" />
					<input type="text" placeholder="请输入您要咨询的业务" />
				</div>
			</div>
			<div class="bottom">
				<img src="../assets/static/home/btn.png" alt="" />
			</div>
		</div>
		<div class="homeBottom">
			<div class="left">
				<div class="leftPic">
					<div class="logoImg" style="width: 300px">
						<img src="../assets/static/logo.png" style="width: 100%" />
					</div>
					<div class="logoTitle"></div>
					<div class="logoText"></div>
				</div>
				<div class="leftText">专注互联网先锋技术研发</div>
				<div class="leftText" style="top: 240px">
					根据您的需求定制企业专属战略解决方案
				</div>
			</div>
			<div class="right">
				<div class="rightItem">
					<img src="../assets/static/PLACE_03.png" />
					<div class="rightItemText">
						上海总部：上海市嘉定区众仁路375号勇立大厦M22室
					</div>
				</div>
				<div class="rightItem">
					<img src="../assets/static/TEL_06.png" />
					<div class="rightItemText">13028402295 / 18221255670</div>
				</div>
				<div class="rightItem">
					<img src="../assets/static/MAIL_09.png" />
					<div class="rightItemText">zhangyiling@sulianinfo.com</div>
				</div>
			</div>

			<img src="../assets/static/ewm.png" style="margin-left:50px" />
		</div>

		<div class="bottomNavie">
			<div @click="touteTo('/development')" style="
    font-size: 24px;
">定制开发</div>
			<div>/</div>
			<div @click="touteTo('/case')" style="
    font-size: 24px;
">客户案例</div>

			<div>/</div>
			<div @click="touteTo('/about')" style="
    font-size: 24px;
">关于我们</div>
		</div>
		<div style="width: 350px; margin: 0 auto; padding: 20px 0">
			<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009665" style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "><img src="../assets/static/beian.png" style="float:left;" />
				<p style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #4e8dff;
          ">
				沪公网安备31011402009665号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	沪ICP备2022002119号
				</p>
			</a>
			

		</div>

	</div>
</template>

<script>
	export default {
		methods: {
			touteTo(val) {
				this.$router.push(val);
			}
		}
	};
</script>
<style lang="scss" scoped>
	.bottomNavie {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 50%;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.homeTop {
		img {
			width: 100%;
		}
	}

	.contactUs {
		margin-top: 70px;
		margin-bottom: 20px;

		.top {
			display: flex;
			justify-content: space-between;
			width: 80%;
			margin: 0 auto;

			.item {
				background-color: #0d1018;
				width: 360px;
				height: 60px;
				border-radius: 30px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 30px;
					height: 30px;
				}

				input {
					border: 0px;
					outline: none;
					width: 222px;
					height: 20px;
					background-color: #0d1018;
					color: #f9f9f9;
					margin-left: 40px;
				}

				input::-webkit-input-placeholder {
					color: #f9f9f9;
					font-weight: 700;
					font-size: 12px;
				}
			}
		}

		.bottom {
			text-align: center;
			margin-top: 100px;

			img {
				width: 863px;
				height: 62px;
			}
		}
	}

	.left {
		position: relative;
		margin-left: 300px;
	}

	.leftText {
		color: #1769ff;
		font-weight: 500;
		font-size: 28px;
		margin-top: 20px;
	}

	.homeBottom {
		display: flex;
		align-items: flex-end;
		margin-top: 90px;
	}

	.right {
		margin-left: 300px;
	}

	.rightItem {
		display: flex;
		margin-top: 20px;

		img {
			width: 20px;
			height: 22px;
		}

		.rightItemText {
			margin-left: 20px;
			margin-top: 3px;
			width: 350px;
		}
	}
</style>
